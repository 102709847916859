<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="presence-list">
      <ion-icon name="time"></ion-icon>
      <ion-label>{{ 'TABS.PRESENCES' | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="service-sv">
      <ion-icon name="calendar"></ion-icon>
      <ion-label>{{ 'TABS.SERVICES_SV' | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="task-list">
      <ion-icon name="clipboard"></ion-icon>
      <ion-label>{{ 'TABS.TASKS' | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="ticket-list">
      <ion-icon name="warning"></ion-icon>
      <!-- <ion-badge mode="ios" color="danger" *ngIf="todoIncidencesTotal">{{ todoIncidencesTotal }}</ion-badge> -->
      <ion-label>Tickets</ion-label>
    </ion-tab-button>
    <!-- <ion-tab-button tab="dependence-list" *ngIf="user.role === 'Cliente' || user.role === 'Responsable'">
      <ion-icon name="business"></ion-icon>
      <ion-label>Dependencias</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="worker-list" *ngIf="user.role === 'Responsable'">
      <ion-icon name="people"></ion-icon>
      <ion-label>Operarios</ion-label>
    </ion-tab-button> -->
  </ion-tab-bar>
</ion-tabs>
