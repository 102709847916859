import { NgModule } from '@angular/core';
import { RouterModule, Router, Routes } from '@angular/router';
import { AuthGuard } from '../05-shared/guards/auth.guard';
import { LoginGuard } from '../05-shared/guards/login.guard';
import { TabsPage } from '../04-pages/tabs/tabs.page';
export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('../04-pages/login/login.page').then((m) => m.LoginPage)
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('../04-pages/signup/signup.page').then((m) => m.SignupPage),
  },
  {
    path: '',
    loadComponent: () =>
      import('../04-pages/project/project.page').then((m) => m.ProjectPage),
    children: [
      {
        path: 'ensayo',
        loadChildren: () => import('../99-ensayo/ensayo.module').then(m => m.EnsayoPageModule)
      },
      // { path: 'presence-list', loadComponent: () => import('./04-pages/presence-list/presence-list.page').then(m => m.PresenceListPage), canActivate: [AuthGuard] },
      {
        path: 'presence-expected-details/:expectedPresenceId',
        loadComponent: () =>
          import(
            '../04-pages/presence-expected-details/presence-expected-details.page'
          ).then((m) => m.PresenceExpectedDetailsPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'presence-new',
        loadComponent: () =>
          import('../04-pages/presence-new/presence-new.page').then(
            (m) => m.PresenceNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'presence-details/:presenceId',
        loadComponent: () =>
          import('../04-pages/presence-details/presence-details.page').then(
            (m) => m.PresenceDetailsPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'presence-edit/:presenceId',
        loadComponent: () =>
          import('../04-pages/presence-edit/presence-edit.page').then(
            (m) => m.PresenceEditPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'presence-expected-register/:expectedPresenceId',
        loadComponent: () =>
          import(
            '../04-pages/presence-expected-register/presence-expected-register.page'
          ).then((m) => m.PresenceExpectedRegisterPage),
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('../04-pages/user-profile/user-profile.page').then((m) => m.UserProfilePage),
        canActivate: [AuthGuard],
      }, {
        path: 'profile/:id',
        loadComponent: () =>
          import('../04-pages/user-profile/user-profile.page').then((m) => m.UserProfilePage),
        canActivate: [AuthGuard],
      },
      {
        path: 'tabs',
        component: TabsPage,
        canActivate: [AuthGuard],
        children: [
          { path: 'presence-list', loadComponent: () => import('../04-pages/presence-list/presence-list.page').then((m) => m.PresenceListPage) },
          { path: 'service-sv', loadComponent: () => import('../04-pages/service-sv-list/services-sv-list.page').then((m) => m.ServiceSvListPage) },
          { path: 'task-list', loadComponent: () => import('../04-pages/task-list/task-list.page').then((m) => m.TaskListPage) },
          { path: 'ticket-list', loadComponent: () => import('../04-pages/ticket-list/ticket-list.page').then((m) => m.TicketListPage) },
          { path: '', redirectTo: '/tabs/presence-list', pathMatch: 'full' },
        ]
      },

      {
        path: 'service-extra-list',
        loadComponent: () =>
          import('../04-pages/service-extra-list/service-extra-list.page').then(
            (m) => m.ServiceExtraListPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-sv-extra-detail/:serviceId',
        loadComponent: () =>
          import('../04-pages/service-sv-extra-detail/service-sv-extra-detail.page').then(
            (m) => m.ServiceSvExtraDetailPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-sv-list',
        loadComponent: () =>
          import(
            '../04-pages/service-sv-list/services-sv-list.page'
          ).then((m) => m.ServiceSvListPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-sv-contract-detail/:serviceId/:workerDni',
        loadComponent: () =>
          import(
            '../04-pages/service-sv-contract-detail/service-sv-contract-detail.page'
          ).then((m) => m.ServiceSvContractDetailPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependence-new/:companyId/:workplaceNum',
        loadComponent: () =>
          import('../04-pages/dependence-new/dependence-new.page').then(
            (m) => m.DependenceNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependence-tasks/:dependenceId',
        loadComponent: () =>
          import('../04-pages/dependence-tasks/dependence-tasks.page').then(
            (m) => m.DependenceTasksPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependences/:companyId/:workplaceNum',
        loadComponent: () =>
          import(
            '../04-pages/dependences-selection/dependences-selection.page'
          ).then((m) => m.DependencesSelectionPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependences-selection/:companyId/:workplaceNum/:roadmapId',
        loadComponent: () =>
          import(
            '../04-pages/dependences-selection/dependences-selection.page'
          ).then((m) => m.DependencesSelectionPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependence-detail/:dependenceId',
        loadComponent: () =>
          import('../04-pages/dependence-detail/dependence-detail.page').then(
            (m) => m.DependenceDetailPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'dependence-list',
        loadComponent: () =>
          import('../04-pages/dependence-list/dependence-list.page').then(
            (m) => m.DependenceListPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'task-new',
        loadComponent: () =>
          import('../04-pages/task-new/task-new.page').then((m) => m.TaskNewPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'ticket-details/:ticketId',
        loadComponent: () =>
          import('../04-pages/ticket-details/ticket-details.page').then(
            (m) => m.TicketDetailsPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'ticket-action-new/:ticketId',
        loadComponent: () =>
          import('../04-pages/ticket-action-new/ticket-action-new.page').then(
            (m) => m.TicketActionNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'ticket-new/:companyId/:workplaceNum',
        loadComponent: () =>
          import('../04-pages/ticket-new/ticket-new.page').then(
            (m) => m.TicketNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'incidence-list',
        loadComponent: () =>
          import('../04-pages/incidence-list/incidence-list.page').then(
            (m) => m.IncidenceListPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'incidence-new',
        loadComponent: () =>
          import('../04-pages/incidence-new/incidence-new.page').then(
            (m) => m.IncidenceNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'incidence-detail/:incidenceId',
        loadComponent: () =>
          import('../04-pages/incidence-detail/incidence-detail.page').then(
            (m) => m.IncidenceDetailPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'control-check',
        loadComponent: () =>
          import('../04-pages/control-check/control-check.page').then(
            (m) => m.ControlCheckPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'map',
        loadComponent: () =>
          import('../04-pages/map/map.page').then(
            (m) => m.MapPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'link/:tagId',
        loadComponent: () =>
          import('../04-pages/link/link.page').then((m) => m.LinkPage),
        canActivate: [AuthGuard],
      },
      {
        path: 'notification-list',
        loadComponent: () =>
          import('../04-pages/notification-list/notification-list.page').then(
            (m) => m.NotificationListPage
          ),
        canActivate: [AuthGuard],
      },
      //{path: '', redirectTo: 'home', pathMatch: 'full'},
      {
        path: 'presence-expected-edit/:expectedPresenceId',
        loadComponent: () =>
          import(
            '../04-pages/presence-expected-edit/presence-expected-edit.page'
          ).then((m) => m.PresenceExpectedEditPage),
      },
      {
        path: 'presence-expected-new',
        loadComponent: () =>
          import(
            '../04-pages/presence-expected-new/presence-expected-new.page'
          ).then((m) => m.PresenceExpectedNewPage),
      },
      {
        path: 'service-presence-new',
        loadComponent: () =>
          import('../04-pages/service-presence-new/service-presence-new.page').then(
            (m) => m.ServicePresenceNewPage
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-sv-new/:companyId/:workplaceNum/:requesterId',
        loadComponent: () =>
          import('../04-pages/service-sv-new/service-sv-new.page').then(
            (m) => m.ServiceSvNewPage
          ),
      },
      {
        path: 'service-sv-detail/:serviceSvExtraId',
        loadComponent: () =>
          import('../04-pages/service-sv-detail/service-sv-detail.page').then(
            (m) => m.ServiceSvDetailPage
          ),
      },
      {
        path: 'services-sv', //work-orders -> Validar rutas
        loadComponent: () =>
          import('../04-pages/service-sv/service-sv.page').then(
            (m) => m.ServiceSvPage
          ),
      },
      {
        path: 'workplaces', 
        loadComponent: () =>
          import('../04-pages/workplaces/workplaces.page').then(
            (m) => m.WorkplacesPage
          )
      },
      { path: '', redirectTo: '/tabs/presence-list', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: '/tabs/presence-list' },


];
